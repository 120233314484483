import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bcd86e4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal--actions flex justify-end" }
const _hoisted_2 = {
  key: 2,
  class: "btn btn--small btn--main btn--disabled"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.data.content.cancelName)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "btn btn--small btn--white",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('removeModal')))
        }, _toDisplayString(_ctx.data.content.cancelName), 1))
      : _createCommentVNode("", true),
    (!_ctx.pending)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: "btn btn--small btn--main",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
        }, _toDisplayString(_ctx.data.content.submitName), 1))
      : (_openBlock(), _createElementBlock("button", _hoisted_2, "Обработка..."))
  ]))
}